var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Select orders","show":_vm.show,"size":"xl"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"Selected","bold":""}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.ordersSelected.length))]),_c('CLink',{staticClass:"ml-2 d-flex align-items-center",on:{"click":_vm.onSelectAll}},[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-check"}}),_c('TMessage',{attrs:{"content":"Select all","bold":""}})],1),(_vm.ordersSelected.length)?_c('CLink',{staticClass:"ml-2 d-flex text-danger align-items-center",on:{"click":function($event){_vm.ordersSelected = []}}},[_c('CIcon',{staticClass:"mr-1",attrs:{"name":"cil-x-circle"}}),_c('TMessage',{attrs:{"content":"Deselect all","bold":"","color":"text-danger"}})],1):_vm._e()],1),_c('TButton',{staticClass:"ml-auto",attrs:{"options":{ disabled: _vm.lodash.isEmpty(_vm.ordersSelected) },"variant":"outline","content":"Select","icon":"cil-check"},on:{"click":_vm.submitOrdersSelected}})],1)]},proxy:true}])},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"order.payment_orders","resource":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 py-1 ml-1 text-center my-auto",attrs:{"col":"12"}},[_c('TInputCheckbox',{attrs:{"checked":_vm.lodash.findIndex(_vm.ordersSelected, ['id', item.id]) !== -1},on:{"change":function($event){return _vm.selectOrder(item)}}})],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{staticClass:"text-nowrap",attrs:{"content":item.customer.name,"noTranslate":""}})],1)]}},{key:"contract_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{staticClass:"text-nowrap",attrs:{"content":item.contract_id,"noTranslate":""}})],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.id,"to":_vm.lodash.getReferenceLink('order', item.id)}})],1)]}},{key:"shipping_fee_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.lading_bills),function(lading_bill,index){return _c('TMessageMoney',{key:'shipping-fee-to-pay-' + index,attrs:{"amount":lading_bill.freight_charge_outstanding,"currency":lading_bill.currency_id}})}),1)]}},{key:"service_fee_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.service_fee_outstanding,"currency":item.service_fee_currency_id}})],1)]}},{key:"purchase_cost_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_outstanding,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"shipping_fee",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},_vm._l((item.lading_bills),function(lading_bill,index){return _c('div',{key:'shipping-fee-' + index,staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":lading_bill.freight_charge_paid,"currency":lading_bill.currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":lading_bill.freight_charge,"currency":lading_bill.currency_id,"color":"muted"}})],1)}),0)]}},{key:"service_fee",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.service_fee_paid,"currency":item.service_fee_currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.service_fee,"currency":item.service_fee_currency_id,"color":"muted"}})],1)])]}},{key:"purchase_cost",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.purchase_cost_paid,"currency":item.purchase_cost_currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.purchase_cost,"currency":item.purchase_cost_currency_id,"color":"muted"}})],1)])]}},{key:"lading_bills",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.lading_bills),function(lading_bill,index){return _c('TMessage',{key:'ldb-id' + index,attrs:{"content":lading_bill.id}})}),1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Order Id","value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"lading_bills-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Bill of lading code","value":_vm.filter.lading_bill_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "lading_bill_id", $event)},_vm.filterChange]}})]},proxy:true},(!_vm.customer_id)?{key:"customer-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)},"change":_vm.filterChange}})]},proxy:true}:null,{key:"contract_id-filter",fn:function(){return [_c('SSelectContract',{attrs:{"value":_vm.filter.contract_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "contract_id", $event)},"change":_vm.filterChange}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }