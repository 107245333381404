var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableSimple',{attrs:{"fields":_vm.itemFields,"items":_vm.items},on:{"update:create":_vm.onInputItem},scopedSlots:_vm._u([{key:"_-header",fn:function(){return [_c('TButton',{attrs:{"icon":"cil-plus","size":"sm","tooltip":"Add order","variant":"ghost"},on:{"click":function($event){_vm.showModalSelectOrder = true}}})]},proxy:true},{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TButtonRemove',{on:{"click":function($event){return _vm.removeItem(item)}}})],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.order.customer.name,"noTranslate":""}})],1)]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.description,"noTranslate":"","editable":""},on:{"change":function($event){item.description = $event}}})],1)]}},{key:"purchase_cost",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.order.purchase_cost_paid,"currency":item.order.purchase_cost_currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.order.purchase_cost,"currency":item.order.purchase_cost_currency_id,"color":"muted"}})],1)])]}},{key:"purchase_cost_to_pay",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.order.purchase_cost_to_pay,"currency":item.order.purchase_cost_currency_id,"editable":"","color":_vm.getColorCostOfGoodsToPay(item.order)},on:{"change":function($event){item.order.purchase_cost_to_pay = $event}}})],1)]}},{key:"result-purchase_cost_to_pay",fn:function(){return [_c('th',[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":"Total:"}}),_c('i',[_c('TMessageMoney',{staticClass:"ml-1",attrs:{"amount":_vm.getTotalCostOfGoodsToPay(),"currency":_vm.currency_id,"color":_vm.getColorTotal()}})],1)],1)])]},proxy:true}])}),_c('ModalSelectOrders',{attrs:{"show":_vm.showModalSelectOrder,"type":"purchase_cost","customer_id":_vm.customer_id},on:{"update:show":function($event){_vm.showModalSelectOrder=$event},"select":_vm.addOrders}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }