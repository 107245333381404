var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableSimple',{attrs:{"fields":_vm.itemFields,"items":_vm.items},on:{"update:create":_vm.onInputItem},scopedSlots:_vm._u([{key:"_-header",fn:function(){return [_c('TButton',{attrs:{"icon":"cil-plus","size":"sm","tooltip":"Add wallets","variant":"ghost"},on:{"click":function($event){_vm.showModalSelectWallet = true}}})]},proxy:true},{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TButtonRemove',{attrs:{"noConfirm":""},on:{"click":function($event){return _vm.removeItem(item)}}})],1)]}},{key:"wallet",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageWallet',{attrs:{"data":item}})],1)]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.description,"noTranslate":"","editable":""},on:{"change":function($event){item.description = $event}}})],1)]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount,"currency":item.currency_id,"editable":""},on:{"change":function($event){item.amount = $event}}})],1)]}},{key:"result-amount",fn:function(){return [_c('th',[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":"Total:"}}),_c('i',[_c('TMessageMoney',{staticClass:"ml-1",attrs:{"amount":_vm.getTotalDeposit(),"currency":_vm.currency_id,"color":_vm.getColorTotal()}})],1)],1)])]},proxy:true}])}),_c('ModalSelectWallets',{attrs:{"show":_vm.showModalSelectWallet,"currency_id":_vm.currency_id},on:{"update:show":function($event){_vm.showModalSelectWallet=$event},"select":_vm.addWallets}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }