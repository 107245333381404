var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableSimple',{attrs:{"fields":_vm.itemFields,"items":_vm.items,"resource":""},on:{"update:create":_vm.onInputItem},scopedSlots:_vm._u([{key:"_-header",fn:function(){return [_c('TButton',{attrs:{"icon":"cil-plus","size":"sm","tooltip":"Add lading bill","variant":"ghost"},on:{"click":function($event){_vm.showModalSelectOrder = true}}})]},proxy:true},{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TButtonRemove',{on:{"click":function($event){return _vm.removeItem(item)}}})],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.lading_bill.customer.name,"noTranslate":""}})],1)]}},{key:"lading_bill",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.lading_bill.name || item.lading_bill.id,"noTranslate":""}})],1)]}},{key:"freight_charge",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.lading_bill.freight_charge_paid}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.lading_bill.freight_charge,"currency":item.lading_bill.currency_id}})],1)])]}},{key:"freight_charge_to_pay",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_c('TMessageMoney',{attrs:{"editable":"","amount":item.lading_bill.freight_charge_to_pay,"currency":_vm.currency_id},on:{"change":function($event){item.lading_bill.freight_charge_to_pay = $event}}})],1)]}},{key:"result-freight_charge_to_pay",fn:function(){return [_c('th',[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":"Total:"}}),_c('i',[_c('TMessageMoney',{staticClass:"ml-1",attrs:{"amount":_vm.getTotal(),"currency":_vm.currency_id,"color":_vm.getColorTotal()}})],1)],1)])]},proxy:true}])}),_c('ModalSelectLadingBills',{attrs:{"show":_vm.showModalSelectOrder,"type":"service_fee","customer_id":_vm.customer_id},on:{"update:show":function($event){_vm.showModalSelectOrder=$event},"select":_vm.addLadingBills}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }