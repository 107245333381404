var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Select wallets","show":_vm.show,"size":"lg"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex w-100"},[_c('TMessage',{attrs:{"content":"Selected: "},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(_vm._s(_vm.walletsSelected.length))]},proxy:true}])}),_c('TButton',{staticClass:"ml-auto",attrs:{"options":{ disabled: _vm.lodash.isEmpty(_vm.walletsSelected) },"variant":"outline","content":"Select","icon":"cil-check"},on:{"click":_vm.submitOrdersSelected}})],1)]},proxy:true}])},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"accounting.wallets_users","resource":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 py-1 ml-1 text-center my-auto",attrs:{"col":"12"}},[_c('TInputCheckbox',{attrs:{"checked":_vm.lodash.findIndex(_vm.walletsSelected, ['id', item.id]) !== -1},on:{"change":function($event){return _vm.selectWallet(item)}}})],1)]}},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id}})],1)]}},{key:"wallet",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageWallet',{attrs:{"data":item}})],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id}})],1)]}},{key:"amount_available_for_payment",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount_available_for_payment,"currency":item.currency_id}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"wallet-filter",fn:function(){return [_c('div',{staticClass:"d-inline-flex w-100"},[_c('SSelectCustomer',{staticClass:"w-100",attrs:{"value":_vm.filter.walletable_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "walletable_id", $event)},"change":_vm.filterChange}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }